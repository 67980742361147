import * as Bowser from 'bowser';
import('./bootstrap').catch(err => console.error(err));

const browser = Bowser.getParser(window.navigator.userAgent);
window.onload = async () => {
    const isBrave = (window.navigator['brave'] && (await window.navigator['brave']?.isBrave())) || false;
    if (browser.getBrowserName() === 'Safari' || isBrave) {
        document.body.classList.add('no-smartbanner');
    }
};

import('./bootstrap').catch(err => console.error(err));
